// Blue navbar design 
.blue-nav {
    .navbar-brand, .navbar-button {
        img { display: none; }
        img.brand-blue { display: inline-block; }
    }

    nav.main ul li {
         > a {
            color: #31315b !important;
            &:hover {
                color: #6b7c93 !important;
            }
        }
    }

    .dropdown-menu a {
        &:hover {
            color: #6b7c93 !important;
        }
    }

    .navbar-button a {
        color: #31315b !important;
        svg {
            g {
                stroke: #31315b !important;
            }
        }
    }

    .navbar-button a.trial-btn {
        border-color: #31315b !important;

        &:hover {
            background: #31315b;
            color: #fff !important;
        }
    }

    .notifications-icon {
        background: url(/images/new_assets/bell-blue.svg) no-repeat 6px 10px;
    }
}
