// Green navbar design 
.green-nav {
    .navbar-brand, .navbar-button {
        img {
            display: none;
        }
        img.brand-green {
            display: inline-block;
        }
    }

    nav.main ul li {
        > a {
            color: #087261 !important;
            &:hover {
                color: #0ca889 !important; // ask from jeanette
            }
        }
    }

    .dropdown-menu a {

        &:hover {
            color: #0ca889 !important;
        }
    }

    .navbar-button a {
        color: #087261 !important;
        svg {
            g {
                stroke: #087261 !important;
            }
        }
    }

    .navbar-button a.trial-btn {
        border-color: #087261;

        &:hover {
            background: #087261 !important;
            color: #fff !important;
        }
    }

    .notifications-icon {
        background: url(/images/new_assets/bell-green.svg) no-repeat 6px 10px;
    }
}
