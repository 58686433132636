.navbar-background.transparent {
    background: transparent !important;
    background-color: transparent !important;
}
.navbar-background {
    .navbar-button {
        a {
            svg {
                width: 15px;
                height: 13px;
                margin-left: 5px;
                margin-top: -2px;
            }
        }
    }
}
.navbar-background.white-nav {
    .trial-btn {
        border-color: #fff !important;
    }
    .notifications-icon {
        background: url(/images/new/bell-white.svg) no-repeat 6px 10px !important;
    }
}
.navbar-background.dark-blue {
    .nav-item {
        a {
            color: #6b75d0 !important;
        }
    }
    .trial-btn {
        color: #6b75d0 !important;
        border-color: #6b75d0 !important;
    }
    .trial-btn:hover {
        background: #6b75d0 !important;
        color: #fff !important;
    }
    .navbar-button {
        a {
            color: #6b75d0 !important;
            svg {
                g {
                    stroke: #6b75d0 !important;
                }
            }
        }
    }
    .notifications-icon {
        background: url(/images/new/bell-white.svg) no-repeat 6px 10px !important;
    }
}
.navbar-background.violet {
    .nav-item {
        a {
            color: #32325d !important;
        }
    }
    .trial-btn {
        color: #32325d !important;
        border-color: #32325d !important;
    }
    .trial-btn:hover {
        background: #32325d !important;
        color: #fff !important;
    }
    .navbar-button {
        a {
            color: #32325d !important;
            svg {
                g {
                    stroke: #32325d !important;
                }
            }
        }
    }
    .notifications-icon {
        background: url(/images/new/bell-white.svg) no-repeat 6px 10px !important;
    }
}
.navbar-background.blue {
    .nav-study-link {
        color: #66B2E0 !important;
    }
}

nav.main {
    padding-top: 10px;

    ul > li {
        //margin-top: 5px;
    }
}

.navbar-button {
    margin-top: 5px;
}

nav.main ul li {
    font-family: gotham, sans-serif;
    font-size: 15px;
    margin-right: 20px;

    a {
        color: #fff !important;
        &:focus, &:active {
            outline: none !important;
        }
        &:hover {
            //color: #19edac !important;
        }
    }
}
.container-nav {
    width: 100%;
    margin: auto;
    padding-left: 13.2%;
    padding-right: 4.7%;
}
.navbar-expand-lg .navbar-nav {
    width: 100%;
    align-items: center;
    padding-left: 18%;
    justify-content: space-between;
}
.navbar-button a {
    padding: 10px 20px 10px 20px;
    font-family: 'gotham Slim', sans-serif;
    color: white !important;
    font-size: 16px;
    
    margin-left: 10px;
    &:hover {
        color: white;
    }
    img {
        margin-left: 5px;
        width: 15px;
        margin-top: -2px;
    }
}

a.trial-btn {
    &:hover {
        background: #fff !important;
        // border: 1px solid #fff;
        color: #2ebef8 !important;
    }
} 

// design dropdowns
nav.main .dropdown-menu {
    background: #4370fc;

    a {
        font-family: 'gotham', sans-serif;
        font-size: 15px;

        &:hover {
            background: #fff;
        }
    }
}

span.sign-in-icons {
    margin-right: 8px;
}

.dropdown-profile img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-top: -5px;
}

// Notifications 
.notifications-icon {
    display: inline-block;
    width: 36px;
    background: url(/images/new/bell-white.svg) no-repeat 6px 10px;
    height: 34px;
    margin-top: 2px;
}

.notifications-icon.has-notification {
    background-image: url(/images/new/bell-badge-white.svg);
    background-position: 2px 0;
}

a.has-activity-indicator {
    padding-top: 0;
    padding-left: 8px;
    padding-right: 8px;
}

.white-nav {
    .navbar-brand, .navbar-button {
        img {  }
        img.brand-white { display: inline-block; }
    }
}



@import 'navbar/grey-nav';
@import 'navbar/blue-nav';
@import 'navbar/green-nav';

.navbar-toggler .navbar-toggler-icon{
    font-size: 20px;
}
.navbar-toggler .navbar-toggler-icon.color-green {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,114,98, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler .navbar-toggler-icon.color-white {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' xml:space='preserve' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10'%3E%3Cpath d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler .navbar-toggler-icon.color-blue {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(107, 117, 208)'
     stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler .navbar-toggler-icon.color-grey {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(107, 124, 147)'
     stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler .navbar-toggler-icon.color-blue-nav {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(85, 90, 191)'
     stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-toggler .navbar-toggler-icon.color-violet {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(50, 50, 93)'
     stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}


