.dropdown-toggle::after {
    display: none !important;
}

.navbar-brand {
    span {
        line-height: 33.75px;
        font-size: 20px;
        color: white;
        font-weight: bold;
    }
    span.green-nav {
        color: #0a7362;
    }
    span.grey-nav {
        color: #6b7c93;
    }
    span.dark-blue {
        color: #6b75d0;
    }
    span.violet {
        color: #32325d;
    }
    span.blue-nav {
        color: #33335d;
    }
}

.navbar-droppdown-item {
    position: relative;
    cursor: pointer;
    .navbar-dropdown-wrapper {
        z-index: 100;
        display: none;
        top: 40px;
        left: -133px;
        width: 350px;
        position: absolute;
        .navbar-dropdown-hovered {
            width: 100%;
            position: absolute;
            height: 50px;
            left: 0;
            top: -20px;
        }
        .navbar-dropdown-arrow {
            transform: translateX(899px) rotate(45deg);
            position: absolute;
            left: 50%;
            top: -6px;
            margin: 0 0 0 -6px;
            width: 12px;
            height: 12px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 4px 0 0 0;
            background: #fff;
            -webkit-box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
            box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
        }

        .navbar-dropdown-content {
            padding: 20px 35px;
            background: #fff;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            -webkit-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
                0 15px 35px rgba(50, 50, 93, 0.15),
                0 5px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
                0 15px 35px rgba(50, 50, 93, 0.15),
                0 5px 15px rgba(0, 0, 0, 0.1);

            .navbar-dropdown-link {
                padding: 15px 0;
                display: flex;
                width: 100%;
                font-size: 15px;
                line-height: 15px;
                color: #6871e5 !important;
                text-decoration: none;

                .navbar-dropdown-link-icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 13px;
                }

                i.fa {
                    width: 15px;
                    margin-right: 13px;
                    line-height: 13px;
                }

                &:hover {
                    color: #32325d !important;

                    i.fa {
                        color: #6871e5 !important;
                    }
                }
            }
        }
    }
}

.navbar-expanded-item {
    .navbar-dropdown-wrapper {
        width: 520px;
        left: -228px;
        .navbar-dropdown-content {
            .navbar-dropdown-link {
                .navbar-dropdown-image-wrapper {
                    display: inline-block;
                    height: 50px;
                    width: 50px;
                    margin-right: 20px;
                    .navbar-dropdown-image {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url(/images/new_assets/navbar_icons/nav_expanded_courses_icon.svg)
                            no-repeat center;
                    }
                    .navbar-dropdown-image.admissions-image {
                        background: url(/images/new_assets/navbar_icons/nav_expanded_admissions_icon.svg)
                            no-repeat center;
                        background-size: 100% 100%;
                    }
                }
                .navbar-dropdown-text-wrapper {
                    display: inline-block;
                    width: calc(100% - 70px);
                    .navbar-dropdown-title {
                        display: block;
                        text-transform: uppercase;
                        font-size: 15px;
                        line-height: 15px;
                        font-weight: 600;
                    }
                    .navbar-dropdown-subtitle {
                        display: block;
                        color: #8b97a8;
                        font-size: 13px;
                        line-height: 13px;
                        margin-top: 10px;
                    }
                    .color-purple {
                        color: #6872e3d6;
                    }
                    .color-green {
                        color: #2bb280bd;
                    }
                    .color-blue {
                        color: #297bb2bf;
                    }
                    .color-violet {
                        color: #ab40b6;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 520px) {
    .navbar-droppdown-item {
        &:hover {
            .navbar-dropdown-wrapper {
                display: block;
            }
        }
    }
}

nav.main .dropdown-menu {
    padding: 0;
    width: 280px;
    left: -185px;
    background: #fff;
    border: none;
    top: 48px;

    .navbar-dropdown-arrow {
        transform: translateX(899px) rotate(45deg);
        position: absolute;
        right: 65px;
        top: -6px;
        margin: 0 0 0 -6px;
        width: 12px;
        height: 12px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 4px 0 0 0;
        background: #fff;
        -webkit-box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
        box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
    }

    .dropdown-menu-content {
        border-radius: 4px;
        box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
            0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
        padding: 13px 0 10px 0;

        hr {
            margin: 10px 0;
        }

        .profile-item {
            padding: 13px 15px 0 15px;
        }

        .dropdown-item {
            font-family: "gotham Slim", sans-serif;
            color: #78889d !important;

            .dropdown-profile-item {
                display: flex;
                .dropdown-profile-img {
                    width: 45px;
                    flex-shrink: 0;
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
                .dropdown-profile-text-wrapper {
                    padding-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .dropdown-profile-username {
                        color: #494949;
                        font-size: 16px;
                        line-height: 27px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .dropdown-profile-text {
                        font-size: 14px;
                        line-height: 14px;
                        display: block;
                    }
                }
            }
        }

        .nb-dd-section {
            padding: 0 35px;
            .nb-dd-sub-heading {
                color: #7cb9fc;
                font-size: 14px;
                margin-bottom: 5px;
                display: block;
                font-family: "gotham Slim", sans-serif;
            }
            .nb-dd-heading {
                margin-bottom: 5px;
                display: block;
                color: #494949;
                font-size: 16px;
                font-family: "gotham Slim", sans-serif;
            }
            .nb-dd-heading-link {
                cursor: pointer;
                display: block;
                color: #78889d !important;
                font-family: "gotham Slim", sans-serif;
                padding: 8px 0;
                .nb-dd-heading-link-icon {
                    width: 20px;
                    margin-right: 15px;
                    display: inline-block;
                    vertical-align: top;
                }
                .nb-dd-heading-link-text {
                    display: inline-block;
                    width: calc(100% - 45px);
                    line-height: 17px;
                }

                &:hover {
                    .nb-dd-heading-link-text {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

//.studyfeed-navbar {
//    background: url(/images/new_assets/backgrounds/nav-bg.png) no-repeat;
//    background-size: 100% 100%;
//    height: 51px;
//    .container-nav {
//        width: 100%;
//        padding-left: 13%;
//        .navbar {
//            height: 51px;
//            padding-bottom: 10px;
//            .navbar-search {
//                width: 546px;
//                height: 31px;
//                border: 1px solid #afd6f9;
//                border-radius: 15px;
//                padding-left: 45px;
//                margin-left: 20px;
//                outline: none;
//                &::-webkit-input-placeholder {
//                    color: #b1b6ba;
//                    font-size: 13px;
//                }
//                &::-moz-placeholder {
//                    color: #b1b6ba;
//                    font-size: 13px;
//                }
//                &:-ms-input-placeholder {
//                    color: #b1b6ba;
//                    font-size: 13px;
//                }
//                &:-moz-placeholder {
//                    color: #b1b6ba;
//                    font-size: 13px;
//                }
//            }
//            .navbar-collapse {
//                .navbar-nav {
//                    .navbar-wrapper {
//                        padding-left: 0;
//                        .trial-btn {
//                            border-color: #fff;
//                        }
//                    }
//                    a {
//                        cursor: pointer;
//                    }
//                    .nav-study-icon {
//                        display: inline-block;
//                        width: 36px;
//                        height: 34px;
//                        margin: 0 9px;
//                        position: relative;
//                        .count {
//                            color: #dd1d1d;
//                            width: 18px;
//                            height: 18px;
//                            display: block;
//                            background-color: #dd1d1d;
//                            border-radius: 50%;
//                            text-align: center;
//                            line-height: 18px;
//                            font-size: 12px;
//                            position: absolute;
//                            right: 3px;
//                            top: 8px;
//                        }
//                    }
//                    .message-icon {
//                        background: url(/images/new_assets/nav-study-message-icon.svg) no-repeat 6px 10px;
//                    }
//                    .friend-icon {
//                        background: url(/images/new_assets/nav-study-friend-icon.svg) no-repeat 6px 10px;
//                    }
//                    .notification-icon {
//                        background: url(/images/new_assets/nav-study-notification-icon.svg) no-repeat 6px 10px;
//                        position: relative;
//                        .notifications-count {
//                            color: #fff;
//                            width: 18px;
//                            height: 18px;
//                            display: block;
//                            background-color: #dd1d1d;
//                            border-radius: 50%;
//                            text-align: center;
//                            line-height: 18px;
//                            font-size: 12px;
//                            position: absolute;
//                            right: 3px;
//                            top: 8px;
//                        }
//                    }
//                    .notification-icon.has-notification {
//                        background: url(/images/new_assets/nav-study-notification-active-icon.svg) no-repeat 6px 10px;
//                    }
//                    .plus-icon {
//                        background: url(/images/new_assets/nav-study-plus-icon.svg) no-repeat 6px 10px;
//                    }
//                    .nav-item.dropdown {
//                        margin-top: 3px;
//                        .nav-link.dropdown-toggle.dropdown-profile {
//                            padding: 0;
//                            margin: 0;
//                            .spark-nav-profile-photo {
//                                width: 46px;
//                                height: 46px;
//                                //margin-right: 60px;
//                            }
//                        }
//                    }
//                    .nav-study-link {
//                        font-size: 13px;
//                        color: #ffffff;
//                        line-height: 36px;
//                        padding: 0 14px;
//                        border-left: 1px solid #4d5bc4;
//                        border-right: 1px solid #4d5bc4;
//                        height: 36px;
//                        margin-top: 3px;
//                        margin-right: 14px;
//                    }
//                    .dropdown-menu {
//                        top: 45px;
//                    }
//                    .study-dropdown {
//                        width: 170px;
//                        left: auto;
//                        right: 137px;
//                        top: 55px;
//                    }
//                }
//            }
//        }
//    }
//}

// Mcat Physical Sciences Course

.navbar-dropdown-link.physical-strategy-course {
    .navbar-dropdown-image-wrapper {
        .navbar-dropdown-image {
            background: url(/images/new_assets/navbar_icons/nav_expanded_physical_icon.svg) no-repeat center !important;
            background-size: 100% 100%;
        }
    }
}
