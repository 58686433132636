// Green navbar design 
.grey-nav {
    .navbar-brand, .navbar-button {
        img { display: none; }
        img.brand-grey { display: inline-block; }
    }

    nav.main ul li {
        > a {
           color: #6B7C93 !important;
           &:hover {
               color: #32325d !important; // ask from jeanette
           }
       }
   }

   .dropdown-menu a {

        &:hover {
           color: #32325D !important;
       }
   }

   .navbar-button a {
       color: #6B7C93 !important;
       svg {
           g {
               stroke: #6B7C93 !important;
           }
       }
   }

   .navbar-button a.trial-btn {
       border-color: #6B7C93 !important;

       &:hover {
           background: #6B7C93 !important;
           color: #fff !important;
       }
   }

   .notifications-icon {
       background: url(/images/new_assets/bell-green.svg) no-repeat 6px 10px;
   }

}
